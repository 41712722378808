let codes = {
  accessCodes: [
    { name: 'Abbey Murphy', code: 'PP-ABBEYM', type: 'PP' },
    { name: 'Phelan Caswell Insurance', code: 'PP-PHELAN', type: 'PP' },
    { name: 'Brian J Pierce Ltd', code: 'PP-BRIANJ', type: 'PP' },
    { name: 'Creane & Creane Ltd', code: 'PP-CREANE', type: 'PP' },
    { name: 'City Financial Marketing (Cfm) Group', code: 'PP-CITYFI', type: 'PP' },
    { name: 'Campion Insurance', code: 'PP-CAMPIO', type: 'PP' },
    { name: 'Cullen Insurance', code: 'PP-CULLEN', type: 'PP' },
    { name: 'Dolmen Insurance', code: 'PP-DOLMEN', type: 'PP' },
    { name: 'Fingal Insurance Group', code: 'PP-FINGAL', type: 'PP' },
    { name: 'First Ireland', code: 'PP-FIRSTI', type: 'PP' },
    { name: 'Frank Glennan Ltd', code: 'PP-FRANKG', type: 'PP' },
    { name: 'Gogan Insurance Ltd', code: 'PP-GOGANI', type: 'PP' },
    { name: 'Goggin Insurance Ltd', code: 'PP-GOGGIN', type: 'PP' },
    { name: 'Goggin Ins Brokers', code: 'PP-GOGGBR', type: 'PP' },
    { name: 'Hastings', code: 'PP-HASTIN', type: 'PP' },
    { name: 'Hooper Dolan', code: 'PP-HOOPER', type: 'PP' },
    { name: 'Insight Risk Mangament', code: 'PP-INSIGH', type: 'PP' },
    { name: "Mccarthy'S Insurance Group", code: 'PP-MCCART', type: 'PP' },
    { name: 'Mcgiven Flynn', code: 'PP-MCGIVE', type: 'PP' },
    { name: 'Mj Kelleher & Sons', code: 'PP-MJKELL', type: 'PP' },
    { name: 'Murphy Insurance Youghal Ltd', code: 'PP-MURPHY', type: 'PP' },
    { name: 'Munster Group', code: 'PP-MUNSTE', type: 'PP' },
    { name: 'National Insurance Ltd', code: 'PP-NATION', type: 'PP' },
    { name: 'Mike Murphy Ltd', code: 'PP-MIKEMU', type: 'PP' },
    { name: "O'Callaghan Ins", code: 'PP-OCALLA', type: 'PP' },
    { name: "O'Leary Insurance Group", code: 'PP-OLEARY', type: 'PP' },
    { name: 'Pat Britton & Co Ltd', code: 'PP-PATBRI', type: 'PP' },
    { name: 'Parish Life T/A Premier Insurance', code: 'PP-PARISH', type: 'PP' },
    { name: 'Pembroke Insurance Ltd', code: 'PP-PEMBRO', type: 'PP' },
    { name: 'Sheridan Is Ltd / Wexford Fin Services', code: 'PP-INNOVU', type: 'PP' },
    { name: 'Sheridan Colohan Ins Brokers', code: 'PP-SHERID', type: 'PP' },
    { name: 'Sparrow Insurance', code: 'PP-SPARRO', type: 'PP' },
    { name: 'Stuart Insurance', code: 'PP-STUART', type: 'PP' },
    { name: 'Sullivan Insurance', code: 'PP-SULLIV', type: 'PP' },
    { name: 'Thompson Insurance', code: 'PP-THOMPS', type: 'PP' },
    { name: 'Gallivan Murphy Insurance', code: 'PP-GALLIV', type: 'PP' },
    { name: 'Aon Insurance', code: 'CP-AONINS', type: 'CP' },
    { name: 'Arachas', code: 'CP-ARACHA', type: 'CP' },
    { name: 'Aston Lark', code: 'CP-ASTONL', type: 'CP' },
    { name: 'Lockton', code: 'CP-LOCKTO', type: 'CP' },
    { name: 'Marsh', code: 'CP-MARSH', type: 'CP' },
    { name: 'Willis Towers Watson', code: 'CP-WILLIS', type: 'CP' },
    { name: 'Chill Insurance', code: 'CP-CHILLI', type: 'CP' },
    { name: 'Crotty Group', code: 'CP-CROTTY', type: 'CP' },
    { name: 'Jlt Insurance', code: 'CP-JLTINS', type: 'CP' },
    { name: 'Aa Ireland', code: 'CP-AAIREL', type: 'CP' },
    { name: 'Partrona U/W', code: 'CP-PARTRO', type: 'CP' },
    { name: 'Broker Federation Of Ireland', code: 'PP-BFIRELAND', type: 'PP' },
    { name: 'Abbey Murphy', code: 'PP-ABBEYM', type: 'PP' },
    { name: 'Irish League of Credit Unions', code: 'CP-IRISHL', type: 'CP' },
    { name: 'Howden Insurance (Ireland) Limited', code: 'CP-HOWDEN', type: 'CP' },

    //Province wide - in IE and NI
    { name: 'Alan Brown & Co (Ni) Ltd', code: 'PP-ALANBR', type: 'PP' },
    { name: 'Brady Insurance Services Ltd', code: 'PP-BRADYI', type: 'PP' },
    { name: 'Cip Insurance Brokers Ltd', code: 'PP-CIPINS', type: 'PP' },
    { name: 'Curran Connolly & Co', code: 'PP-CURRAN', type: 'PP' },
    { name: 'E A Davies & Co Ltd', code: 'PP-EADAVI', type: 'PP' },
    { name: 'Ivan Bell T/A Insurance Matters General Brokers', code: 'PP-IVANBE', type: 'PP' },
    { name: 'J Hatty & Co', code: 'PP-JHATTY', type: 'PP' },
    { name: 'J N Chambers Ltd', code: 'PP-JNCHAM', type: 'PP' },
    { name: 'Mccarroll Mcconnell & Co Ltd', code: 'PP-MCCARR', type: 'PP' },
    { name: 'Oakland Insurance Services Ltd', code: 'PP-OAKLAN', type: 'PP' },
    { name: 'Peter Mcaleer Ltd ', code: 'PP-PETERM', type: 'PP' },
    { name: 'OBF insurance ', code: 'PP-OBFINS', type: 'PP' },
    // AXA Internals
    { name: 'Axa ROI-Internal', code: 'AXA-ROI-INT', type: 'AXA' },
    // AXA Elevate
    { name: 'IE Elevate', code: 'IE-ELEVATE', type: 'ELE' },
  ],
};

export const IeBrokerwiseProCodes = {
  codes,
};
